export default {
  displayMode: false,
  // EXTERNAL_URL_BASE: "http://localhost:9000/api/v1",
  //EXTERNAL_URL_BASE: "https://44.239.74.226:9000/api/v1",
  EXTERNAL_URL_BASE: "https://www.mathsolutely.com/api/v1",
  cssTransitionOptions: {
    component: "div",
    transitionName: "TabsAnimation",
    transitionAppear: true,
    transitionAppearTimeout: 0,
    transitionEnter: false,
    transitionLeave: false,
  },
  communications: {
    supportEmailAddress: "contact@mathsolutely.com",
    contactEmailAddress: "contact@mathsolutely.com",
  },
  testOptions: {
    timeLimitInMinutes: 70,
    gridInAnswerMaxLength: 5,
    //Allow numbers, forward slash and dot
    //eslint-disable-next-line
    gridInAnswerRegex: /[0-9.\/]/,
    normalTestHistoryScoresPerPage: 12,
    mobileTestHistoryScoresPerPage: 3,
    tabletTestHistoryScoresPerPage: 5,
  },
  mathjaxOptions: {
    options: {
      renderActions: {
        addMenu: [], //disable the MathJax context menu
      },
    },
    tex: {
      //Allow for processing inline and escaped formulas
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      processEscapes: true,
    },
  },
  planOptions: {
    numTestsLeftForExpirationWarning: 2,
    numDaysLeftForExpirationWraning: 7,
  },
  commit: "ee3a48ac",
};
