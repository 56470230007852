import { axiosInstance, getAxiosInstance } from "Mathsolutely/Util/api";

/**
 * A representation of the current user.
 * @typedef {Object} User
 * @property {boolean} accountAccess True if the user is in good standing, false if the user is suspended
 * @property {Object} location An object capturing the user's submitted location data.
 * @property {string} firstName The user's first name.
 * @property {string} lastName The user's last name.
 * @property {'Administrator'|'Monitor'|'Student'} kind The type/role of the user. Enumerated.
 * @property {string} email The user's email address.
 * @property {string} _id The user's ID.
 */

const AuthenticationService = {
  /**
   * Sends authentication credentials to the server to authenticate a user. If the user's authentication fails,
   * "guest mode", a formality for tracking client state, will be activated.
   * @param username The user's username.
   * @param password The user's password.
   * @return {Promise<{success: boolean, user: User | null}>}
   */
  authenticateUser: async function (username, password) {
    const axios = getAxiosInstance();
    const options = {
      url: "/entry/login",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        username: username,
        password: password,
      },
    };
    try {
      const response = await axios(options);
      this.setUser(response.data.user);
      return { success: true, user: response.data.user };
    } catch (error) {
      // the 500 is a good generic 'catch-all' HTTP server error code if no value to put in error
      return {
        success: false,
        user: null,
        error: error.response ? error.response.status : 500,
      };
    }
  },

  /**
   * Checks if the user is logged in. Whether a user is logged in is determined by the user's JWT access token. If the
   * access token is not expired, the user can be considered logged in. If the token is expired, then the client (us)
   * sends the user's JWT refresh token. If the JWT refresh token is not expired, the user is logged in and receives
   * a new JWT access token. If the refresh token is also expired, the user will be forced to log in again.
   * @return {Promise<boolean>} Returns true if the server says the user can be considered logged in, false otherwise.
   */
  loggedIn: async function () {
    const user = this.getUser();
    if (!!user) {
      return true;
    }

    try {
      // If the JWT access token is valid, then the user can be considered logged in, and we can proceed. This is
      // a very quick, lightweight request.
      const response = await axiosInstance({
        url: "/login-status",
        method: "GET",
        skipAuthRefresh: true,
      });
      if (response.status === 200) {
        // When the user closes the tab the session storage is lost but the cookies will remain. This will
        // ensure the user is able to get their info once the cookies are validated on the server.
        this.setUser(response.data.user);
        axiosInstance({
          url: "/refresh",
          method: "GET",
        });
        return true;
      } else {
        // TODO If the response isn't a 200 code, not sure what to do - It will still be a successful code
        return false;
      }
    } catch (error) {
      return false;
    }
  },

  /**
   * Set the current user in session storage.
   * @param {User} user
   */
  setUser: function (user) {
    sessionStorage.setItem("user", JSON.stringify(user));
  },

  /**
   * Returns the locally-stored user object
   * @return {User} The current user.
   */
  getUser: function () {
    return JSON.parse(sessionStorage.getItem("user"));
  },

  logout: async function () {
    sessionStorage.clear();
    const options = {
      url: "/logout",
      method: "GET",
    };
    // TODO What to do if axios call fails?
    await axiosInstance(options).catch((error) =>
      console.warn("The logout call failed"),
    );
  },
};

export default AuthenticationService;
